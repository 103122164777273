/*! lazysizes - v4.0.1 */
!(function(a, b) {
  var c = function() {
    b(a.lazySizes), a.removeEventListener('lazyunveilread', c, !0);
  };
  (b = b.bind(null, a, a.document)),
    'object' == typeof module && module.exports
      ? b(require('lazysizes'))
      : a.lazySizes ? c() : a.addEventListener('lazyunveilread', c, !0);
})(window, function(a, b, c) {
  'use strict';
  function d(a, c) {
    if (!g[a]) {
      var d = b.createElement(c ? 'link' : 'script'),
        e = b.getElementsByTagName('script')[0];
      c ? ((d.rel = 'stylesheet'), (d.href = a)) : (d.src = a),
        (g[a] = !0),
        (g[d.src || d.href] = !0),
        e.parentNode.insertBefore(d, e);
    }
  }
  var e,
    f,
    g = {};
  b.addEventListener &&
    ((f = /\(|\)|\s|'/),
    (e = function(a, c) {
      var d = b.createElement('img');
      (d.onload = function() {
        (d.onload = null), (d.onerror = null), (d = null), c();
      }),
        (d.onerror = d.onload),
        (d.src = a),
        d && d.complete && d.onload && d.onload();
    }),
    addEventListener(
      'lazybeforeunveil',
      function(a) {
        if (a.detail.instance == c) {
          var b, g, h, i;
          a.defaultPrevented ||
            ('none' == a.target.preload && (a.target.preload = 'auto'),
            (b = a.target.getAttribute('data-link')),
            b && d(b, !0),
            (b = a.target.getAttribute('data-script')),
            b && d(b),
            (b = a.target.getAttribute('data-require')),
            b && (c.cfg.requireJs ? c.cfg.requireJs([b]) : d(b)),
            (h = a.target.getAttribute('data-bg')),
            h &&
              ((a.detail.firesLoad = !0),
              (g = function() {
                (a.target.style.backgroundImage =
                  'url(' + (f.test(h) ? JSON.stringify(h) : h) + ')'),
                  (a.detail.firesLoad = !1),
                  c.fire(a.target, '_lazyloaded', {}, !0, !0);
              }),
              e(h, g)),
            (i = a.target.getAttribute('data-poster')),
            i &&
              ((a.detail.firesLoad = !0),
              (g = function() {
                (a.target.poster = i),
                  (a.detail.firesLoad = !1),
                  c.fire(a.target, '_lazyloaded', {}, !0, !0);
              }),
              e(i, g)));
        }
      },
      !1
    ));
});
