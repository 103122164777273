/**
 * Slide an element down like jQuery's slideDown function using CSS3 transitions.
 * @see https://gist.github.com/ludder/4226288
 * @param  {element}  el
 * @param  {string}   timing
 * @return {void}
 */
function slideDown(el, timing) {
  timing = timing || '300ms ease';

  // Get element height
  el.style.webkitTransition = 'initial';
  el.style.transition = 'initial';
  el.style.visibility = 'hidden';
  el.style.maxHeight = 'initial';
  var height = el.offsetHeight + 'px';
  el.style.removeProperty('visibility');
  el.style.maxHeight = '0';
  el.style.overflow = 'hidden';

  // Begin transition
  el.style.webkitTransition =
    'max-height ' + timing + ', opacity ' + timing + '';
  el.style.transition = 'max-height ' + timing + ', opacity ' + timing + '';
  var endSlideDown = function () {
    el.style.removeProperty('-webkit-transition');
    el.style.removeProperty('transition');
    el.removeEventListener('transitionend', endSlideDown);
  };
  requestAnimationFrame(function () {
    el.addEventListener('transitionend', endSlideDown);
    el.style.maxHeight = height;
    el.style.opacity = '1';
  });
}

/**
 * Slide an element up like jQuery's slideUp function using CSS3 transitions.
 * @see https://gist.github.com/ludder/4226288
 * @param  {element}  el
 * @param  {string}   timing
 * @return {void}
 */
function slideUp(el, timing) {
  timing = timing || '300ms ease';

  // Get element height
  el.style.webkitTransition = 'initial';
  el.style.transition = 'initial';
  var height = el.offsetHeight + 'px';
  el.style.maxHeight = height;
  el.style.overflow = 'hidden';

  // Begin transition
  el.style.webkitTransition =
    'max-height ' + timing + ', opacity ' + timing + '';
  el.style.transition = 'max-height ' + timing + ', opacity ' + timing + '';
  var endSlideDown = function () {
    el.style.removeProperty('-webkit-transition');
    el.style.removeProperty('transition');
    el.removeEventListener(transitionEvent('end'), endSlideDown);
  };
  requestAnimationFrame(function () {
    el.style.maxHeight = '0';
    el.style.opacity = '0';
  });
}

// debounce (inspired by lodash _.debounce)
function debounce(func, wait, immediate) {
  var timeout;
  return function () {
    var context = this,
      args = arguments;
    var later = function () {
      timeout = null;
      if (!immediate) func.apply(context, args);
    };
    var callNow = immediate && !timeout;
    clearTimeout(timeout);
    timeout = setTimeout(later, wait);
    if (callNow) func.apply(context, args);
  };
}

/*!
  * domready (c) Dustin Diaz 2014 - License MIT
  */
!(function (name, definition) {
  if (typeof module != 'undefined') module.exports = definition();
  else if (typeof define == 'function' && typeof define.amd == 'object')
    define(definition);
  else this[name] = definition();
})('domready', function () {
  var fns = [],
    listener,
    doc = typeof document === 'object' && document,
    hack = doc && doc.documentElement.doScroll,
    domContentLoaded = 'DOMContentLoaded',
    loaded =
      doc && (hack ? /^loaded|^c/ : /^loaded|^i|^c/).test(doc.readyState);

  if (!loaded && doc)
    doc.addEventListener(
      domContentLoaded,
      (listener = function () {
        doc.removeEventListener(domContentLoaded, listener);
        loaded = 1;
        while ((listener = fns.shift())) listener();
      })
    );

  return function (fn) {
    loaded ? setTimeout(fn, 0) : fns.push(fn);
  };
});
// function onScrollFinished() {
//   console.log('scroll finished');
// }

function scrollIt(destination, duration = 300, easing = 'linear', callback) {
  const easings = {
    linear(t) {
      return t;
    },
    easeInQuad(t) {
      return t * t;
    },
    easeOutQuad(t) {
      return t * (2 - t);
    },
    easeInOutQuad(t) {
      return t < 0.5 ? 2 * t * t : -1 + (4 - 2 * t) * t;
    },
    easeInCubic(t) {
      return t * t * t;
    },
    easeOutCubic(t) {
      return --t * t * t + 1;
    },
    easeInOutCubic(t) {
      return t < 0.5 ? 4 * t * t * t : (t - 1) * (2 * t - 2) * (2 * t - 2) + 1;
    },
    easeInQuart(t) {
      return t * t * t * t;
    },
    easeOutQuart(t) {
      return 1 - --t * t * t * t;
    },
    easeInOutQuart(t) {
      return t < 0.5 ? 8 * t * t * t * t : 1 - 8 * --t * t * t * t;
    },
    easeInQuint(t) {
      return t * t * t * t * t;
    },
    easeOutQuint(t) {
      return 1 + --t * t * t * t * t;
    },
    easeInOutQuint(t) {
      return t < 0.5 ? 16 * t * t * t * t * t : 1 + 16 * --t * t * t * t * t;
    }
  };

  const start = window.pageYOffset;
  const startTime =
    'now' in window.performance ? performance.now() : new Date().getTime();
  const headerHeight = window.innerWidth > 1024 ? 72 : 52;
  const documentHeight = Math.max(
    document.body.scrollHeight,
    document.body.offsetHeight,
    document.documentElement.clientHeight,
    document.documentElement.scrollHeight,
    document.documentElement.offsetHeight
  );
  const windowHeight =
    window.innerHeight ||
    document.documentElement.clientHeight ||
    document.getElementsByTagName('body')[0].clientHeight;
  // EDITED to fix 72px navbar
  const destinationOffset =
    typeof destination === 'number'
      ? destination
      : destination.offsetTop - headerHeight;
  const destinationOffsetToScroll = Math.round(
    documentHeight - destinationOffset < windowHeight
      ? documentHeight - windowHeight
      : destinationOffset
  );

  if ('requestAnimationFrame' in window === false) {
    window.scroll(0, destinationOffsetToScroll);
    if (callback) {
      callback();
    }
    return;
  }

  function scroll() {
    const now =
      'now' in window.performance ? performance.now() : new Date().getTime();
    const time = Math.min(1, (now - startTime) / duration);
    const timeFunction = easings[easing](time);
    window.scroll(
      0,
      Math.ceil(timeFunction * (destinationOffsetToScroll - start) + start)
    );

    if (Math.ceil(window.pageYOffset) === destinationOffsetToScroll) {
      if (callback) {
        callback();
      }
      return;
    }

    requestAnimationFrame(scroll);
  }

  scroll();
}

// load files programatically function
const load = (function () {
  function _load(tag) {
    return function (url) {
      return new Promise((resolve, reject) => {
        const element = document.createElement(tag);
        let parent = 'body';
        let attr = 'src';

        element.onload = () => resolve(url);
        element.onerror = () => reject(element.error);

        switch (tag) {
          case 'script':
            element.async = true;
            break;
          case 'link':
            element.type = 'text/css';
            element.rel = 'stylesheet';
            attr = 'href';
            parent = 'head';
        }

        element[attr] = url;
        document[parent].appendChild(element);
      });
    };
  }

  return {
    css: _load('link'),
    js: _load('script'),
    img: _load('img')
  };
})();

function removeClassByPrefix(el, prefix) {
  const regx = new RegExp('\\b' + prefix + '.*?\\b', 'g');
  el.className = el.className.replace(regx, '');
  return el;
}

class ImageCards {
  constructor(containerSelector) {
    this.parent = document.querySelector(containerSelector);
    this.items = this.parent.children;
    this.currentIndex = 0;
    // temp
    [...this.items].map((item) => item.classList.add('ready'));
    // this.forward();
  }

  change(targetIndex) {
    // ->
    if (targetIndex > this.currentIndex) {
      this.forward();
      if (targetIndex > this.currentIndex + 1) {
        setTimeout(() => {
          this.forward();
        }, 1000);
      }
    } else {
      // <-
      this.backward();
      if (targetIndex < this.currentIndex - 1) {
        setTimeout(() => {
          this.backward();
        }, 1000);
      }
    }
    this.currentIndex = targetIndex;
  }

  forward() {
    this.items[0].classList.remove('ready');
    setTimeout(() => {
      const firstItemDetached = this.items[0].parentNode.removeChild(this.items[0]);
      this.parent.appendChild(firstItemDetached);
      setTimeout(() => {
        this.items[this.items.length - 1].classList.add('ready');
      }, 50);
    }, 400);
  }

  backward() {
    const i = this.items.length - 1;
    this.items[i].classList.remove('ready');
    setTimeout(() => {
      const lastItemDetached = this.items[i].parentNode.removeChild(
        this.items[i]
      );
      this.parent.insertBefore(lastItemDetached, this.parent.firstChild);
      setTimeout(() => {
        this.items[0].classList.add('ready');
      }, 50);
    }, 400);
  }
}

class InteractiveMap {
  constructor() {
    this.names = [
      'ronys',
      'smetana',
      'charles',
      'meeting'
    ];
    this.rooms = [];
    this.loadData();
    // this.rooms[0].container.dispatchEvent(new MouseEvent('click'));
    $(this.rooms[0].contentElement).addClass('active');
  }

  loadData() {
    const rooms = document.querySelectorAll('.room');
    this.names.map((name) => {
      const groups = [...document.querySelectorAll('.restaurant-map-mlynec g')].filter((g) => {
        return g.id && g.id.startsWith(name);
      });

      //   container element
      const container = groups.filter(
        (g) => !g.id.includes('text') && !g.id.includes('arow')
      )[0];

      //   arrow and text
      const children = groups.filter((g) => g !== container);

      //  contentElement
      const content = [...rooms].filter(
        (r) => r.getAttribute('data-alias') === name
      )[0];
      this.createRoom(container, children, content);
    });
  }

  createRoom(container, children, contentElement) {
    // container.addEventListener('mouseenter', (e) => {
    //   if (
    //     !container.classList.contains('active') &&
    //     !container.classList.contains('active-right')
    //   ) {
    //     container.classList.add('hover');
    //   }
    // });
    // container.addEventListener('mouseleave', (e) => {
    //   if (
    //     !container.classList.contains('active') &&
    //     !container.classList.contains('active-right')
    //   ) {
    //     container.classList.remove('hover');
    //   }
    // });
    container.addEventListener('click', () => {
      if (!$(container).hasClass('active')) {
        this.rooms.map((room) => {
          $(room.container).removeClass('active');
          $(room.contentElement).removeClass('active');
        });
        $(container).addClass('active');
        $(container).removeClass('hover');
        $(contentElement).addClass('active');
      }



      // if (!container.classList.contains('active')) {
      //   this.rooms.map((room) => {
      //     room.container.classList.remove('active');
      //     room.contentElement.classList.remove('active');
      //   });
      //   container.classList.add('active');
      //   container.classList.remove('hover');
      //   contentElement.classList.add('active');
      // }
    });
    this.rooms.push({
      container,
      children,
      contentElement
    });
  }
}
