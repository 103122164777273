$(document).ready(function() {
    /* Mobile menu */
    $(".mobile-header .mobile-toggle").click(e => {
        $(e.currentTarget).toggleClass("active");
        $('.header .nav').toggleClass("open");
        $('body').toggleClass("mobileMenu");
    });

    /* Calling codes */
    function initCallingCodes() {
        $('.js-callingCode').click(function() {
            $('#txtPhone').focus();
        });
        $("#ddlCallingCode").ddslick({
            width: 60,
            height: 300,
            truncateDescription: true,
            showSelectedHTML: false,
            onSelected: function(d){
                //callback function: do something with selectedData;
                $('.js-callingCode').html('+' + d.selectedData.value);
                $('#hdnCallingCode').val(d.selectedData.value);
                $('#txtPhone').css('padding-left', $('.js-callingCode').first().outerWidth(true) + 'px');
                $('#txtPhone').attr('placeholder', $('#dd-option-' + d.selectedData.value).data('placeholder'));
            } 
        });
    }


    function initCustomSelect() {
        /* Custom select */
        var x, i, j, selElmnt, a, b, c;
        /* Look for any elements with the class "custom-select": */
        x = document.getElementsByClassName("custom-select");
        for (i = 0; i < x.length; i++) {
        selElmnt = x[i].getElementsByTagName("select")[0];
        /* For each element, create a new DIV that will act as the selected item: */
        a = document.createElement("DIV");
        a.setAttribute("class", "select-selected");
        a.innerHTML = selElmnt.options[selElmnt.selectedIndex].innerHTML;
        x[i].appendChild(a);
        /* For each element, create a new DIV that will contain the option list: */
        b = document.createElement("DIV");
        b.setAttribute("class", "select-items select-hide");
        var start = x[i].getAttribute('data-showfirst') === 'true' ? 0 : 1;
        for (j = start; j < selElmnt.length; j++) {
                /* For each option in the original select element,
                create a new DIV that will act as an option item: */
                c = document.createElement("DIV");
                c.innerHTML = selElmnt.options[j].innerHTML;
                if(start === 0 && j === 0) {
                    c.setAttribute("class", "same-as-selected");
                }
                c.addEventListener("click", function(e) {
                    /* When an item is clicked, update the original select box,
                    and the selected item: */
                    var y, i, k, s, h;
                    s = this.parentNode.parentNode.getElementsByTagName("select")[0];
                    h = this.parentNode.previousSibling;
                    for (i = 0; i < s.length; i++) {
                    if (s.options[i].innerHTML == this.innerHTML) {
                        s.selectedIndex = i;
                        h.innerHTML = this.innerHTML;
                        y = this.parentNode.getElementsByClassName("same-as-selected");
                        for (k = 0; k < y.length; k++) {
                        y[k].removeAttribute("class");
                        }
                        this.setAttribute("class", "same-as-selected");
                        break;
                    }
                    }
                    h.click();
                });
                b.appendChild(c);
            }
        x[i].appendChild(b);
        a.addEventListener("click", function(e) {
            /* When the select box is clicked, close any other select boxes,
            and open/close the current select box: */
            e.stopPropagation();
            closeAllSelect(this);
            $(this.nextSibling).slideToggle(); //.classList.toggle("select-hide");
            this.classList.toggle("select-arrow-active");
            });
        }

        /* If the user clicks anywhere outside the select box,
        then close all select boxes: */
        document.addEventListener("click", closeAllSelect); 
    }

    function closeAllSelect(e) {
        if(e.target && $(e.target).closest('.custom-checkboxlist--items').length) {
            return;
        }
        /* A function that will close all select boxes in the document,
        except the current select box: */
        var x, y, i, arrNo = [];
        x = document.getElementsByClassName("select-items");
        y = document.getElementsByClassName("select-selected");
        for (i = 0; i < y.length; i++) {
            if (e == y[i]) {
            arrNo.push(i)
            } else {
                y[i].classList.remove("select-arrow-active");
            }
        }
        for (i = 0; i < x.length; i++) {
            if (arrNo.indexOf(i)) {
                $(x[i]).slideUp(); //.classList.add("select-hide");
            }
        }
    }

    function initCustomCheckboxList() {
        document.getElementsByClassName("custom-checkboxlist");

        function fillValue(target) {
            var cbl = $(target).closest('.custom-checkboxlist').find('input[type=checkbox]');
            var result = '';
            for(var i = 0; i < cbl.length; i += 1) {
                if(cbl[i].checked) {
                    result += cbl[i].previousSibling.innerHTML + ", ";
                }
            }
            var val = $(target).closest('.custom-checkboxlist').find('.custom-checkboxlist--value')[0];
            if(result.length > 0) {
                val.innerHTML = result.substring(0, result.length - 2)
            } else {
                val.innerHTML = $(val).attr('data-default');
            }
        }

        $('.custom-checkboxlist--items span').each(function(i, e) {
            var len = $(e).find('label').length;
            var wrap1 = document.createElement('div');
            var wrap2 = document.createElement('div');
            for(var i = 0; i < len; i++) {
                var a = document.createElement('div');
                a.setAttribute('class', 'd-flex');
                a.appendChild(e.children[0]);
                a.appendChild(e.children[0]);
                if(i + 1 <= Math.round((len/2))) {
                    wrap1.appendChild(a);
                } else {
                    wrap2.appendChild(a);
                }
            }
            e.appendChild(wrap1);
            e.appendChild(wrap2);
            fillValue(e);
        });
        $('.custom-checkboxlist--items input').each(function(i, e) {
            $(e).change(function(ev) {
                fillValue(ev.target);
            });
        });
        $('.custom-checkboxlist--value').each(function(i, e) {
            e.addEventListener("click", function(ev) {
                /* When the select box is clicked, close any other select boxes,
                and open/close the current select box: */
                ev.stopPropagation();
                closeAllSelect(this);
                $($(this).siblings('.select-items')[0]).slideToggle(); //classList.toggle("select-hide");
                this.classList.toggle("select-arrow-active");
            }.bind(this));
        });
        $('.custom-checkboxlist--items').each(function(i, e) {
            $(e).removeClass('select-hide');
            var divs = $(e).find('span > div');
            $(divs).each(function(id, d) {
                var max = 0;
                var labels = $(d).find('label');
                $(labels).each(function(ii, l) {
                    if($(l).outerWidth() > max) {
                        max = $(l).outerWidth();
                    }
                });
                $(labels).each(function(ii, l) {
                    $(l).css('min-width', max + 20 + 'px');
                });
            });
            $(e).addClass('select-hide');
        });
    }

    function scrollToError () {
        var top = 0;
        var headerHeight = 105;
        if($(".EditingFormErrorLabel, .error").length) {
            var formGroup = $(".EditingFormErrorLabel, .error").first().closest('.form-group');
            if(formGroup.length) {
                top = formGroup.offset().top;
            } else {
                top = $(".EditingFormErrorLabel, .error").first().offset().top;
            }
        }
        if(top > 0) {
            window.scroll(0, top - headerHeight);
        }
    }

    initCallingCodes();
    initCustomSelect();
    initCustomCheckboxList();
    if (typeof Sys !== 'undefined') {
        Sys.WebForms.PageRequestManager.getInstance().add_endRequest(() => {
            initCallingCodes();
            initCustomSelect();
            initCustomCheckboxList();
            scrollToError();

            if ($(".student-form-registred").length > 0){
                $('html, body').animate({scrollTop:0}, 0);
            }
        })
    }

    if($(".custom-checkboxlist--items.select-hide")){
        $("input").click(function(){
            $(".custom-checkboxlist--value").addClass("select-arrow-active");
        })
    }
});
